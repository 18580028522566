<template>
  <div>
    <div class="tile">
      <h5 class="tile-title mb-4">Dormant Accounts</h5>
      <div class="row">
        <label for="picker" class="form-label">Select Dates:</label>
        <date-range-picker class="mb-4" ref="picker" opens="right" :locale-data="{
          firstDay: 1,
          format: 'dd-mm-yyyy',
          applyLabel: 'Search',
        }" :show-dropdowns="true" v-model="dateRange" @update="getReports">
        </date-range-picker>
        <vue-good-table :columns="columns" :rows="rows" :pagination-options="{
          enabled: true,
        }" :search-options="{
  enabled: true,
  placeholder: 'Search',
}">
          <div slot="emptystate">
            <div class="d-flex justify-content-center">
              <div v-if="isLoading" class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div v-else-if="!isLoading && rows.length <= 0">
                <span>No data</span>
              </div>
            </div>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
import { mutations } from "../store";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Login",
          field: "Login",
        },
        {
          label: "Name",
          field: "Name",
        },
        {
          label: "Country",
          field: "Country",
        },
        {
          label: "Status",
          field: "Status",
        },
        {
          label: "Currency",
          field: "Currency",
        },
        {
          label: "Balance",
          field: "Balance",
        },
        {
          label: "PrevMonthBalance",
          field: "PrevMonthBalance",
        },
        {
          label: "PrevBalance",
          field: "PrevBalance",
        },
        {
          label: "Equity",
          field: "Equity",
        },
        {
          label: "Margin",
          field: "Margin",
        },
        {
          label: "Margin_Free",
          field: "Margin_Free",
        },
      ],
      rows: [],
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
    };
  },
  components: {
    DateRangePicker,
  },
  methods: {
    getReports(dates) {
      this.isLoading = true;
      let { startDate, endDate } = mutations.convertDates(dates);
      this.$axios({
        url: `/reports?from=${startDate}&to=${endDate}`,
      })
        .then(({ data: { reportsList } }) => {
          this.isLoading = false;
          this.rows = reportsList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>