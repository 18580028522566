var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tile"},[_c('h5',{staticClass:"tile-title mb-4"},[_vm._v("Dormant Accounts")]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"form-label",attrs:{"for":"picker"}},[_vm._v("Select Dates:")]),_c('date-range-picker',{ref:"picker",staticClass:"mb-4",attrs:{"opens":"right","locale-data":{
          firstDay: 1,
          format: 'dd-mm-yyyy',
          applyLabel: 'Search',
        },"show-dropdowns":true},on:{"update":_vm.getReports},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
          enabled: true,
        },"search-options":{
  enabled: true,
  placeholder: 'Search',
}}},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"d-flex justify-content-center"},[(_vm.isLoading)?_c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])]):(!_vm.isLoading && _vm.rows.length <= 0)?_c('div',[_c('span',[_vm._v("No data")])]):_vm._e()])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }